import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

export const AsideView = ({
  title,
  children,
  open,
  closeViewer,
  width,
}: {
  title: any;
  children: any;
  open: boolean;
  closeViewer: any;
  width?: any;
}) => {
  return (
    <>
      <BackdropWrapper className="animate" open={open} />
      <Wrapper className="whiteBg animate" open={open} width={width}>
        {/* HEADER */}

        <HeaderWrapper
          open={open}
          className="flexNullCenter gap10 animate"
          style={{
            borderBottom: "1px solid #eee",
            height: 60,
            paddingInline: 10,
          }}
        >
          <IconButton onClick={closeViewer} size="small">
            <Close fontSize="small" />
          </IconButton>
          <div className="capitalize semiBold">{title}</div>
        </HeaderWrapper>

        <div
          style={{
            overflow: "scroll",
            height: "calc(100% - 60px)",
          }}
        >
          {open && children}
        </div>
      </Wrapper>
    </>
  );
};

export const useAsideView = (options: { open: boolean }) => {
  const [open, setOpen] = useState(options.open);
  const openViewer = () => setOpen(true);
  const closeViewer = () => setOpen(false);
  return { open, openViewer, closeViewer };
};

const Wrapper = styled.div<any>`
  min-width: ${(props) => (props.open ? props?.width || "20%" : 0)};
  width: ${(props) => (props.open ? props?.width || "20%" : 0)};
  border-left: 1px solid #eee;

  @media (max-width: 960px) {
    position: absolute;
    height: 100%;
    right: 0;
    width: ${(props) => (props.open ? "100%" : 0)};
    z-index: 10;
  }
`;

const HeaderWrapper = styled.div<any>`
  scale: ${(props) => (props.open ? 1 : 0)};
`;

const BackdropWrapper = styled.div<any>`
  @media (max-width: 960px) {
    background-color: black;
    pointer-events: none;
    opacity: ${(props) => (props.open ? 0.8 : 0)};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 9;
  }
`;
