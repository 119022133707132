import styled from "styled-components";
import { ChatItem } from "./ChatItem";
import { Input } from "antd";
import { CommentRounded, SearchRounded } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { EmptyView, OptionsMenu } from "../../elements/Elements";
import { UsersSVG } from "../../../assets/svg/icons/UsersSVG";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getSocketUrl } from "../../../constants/apis";
import { GROUP } from "../../../constants/constants";
import { Loader } from "../../common/Loader";

export const ChatsList = ({ setChat, chat }: { chat?: any; setChat?: any }) => {
  const websocketRef = useRef<any>(null);
  const { user, authToken } = useAuth();
  const userId = user?.id;
  const [chats, setChats] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(2);
  const [filter, setFilter] = useState<any>();
  const [loading, setLoading] = useState(false);
  const authParams = `?token=${authToken}`;

  const openWebSocket = ({ reconnect }: any = {}) => {
    // return;
    if (userId) {
      setLoading(true);
      const queryParams = filter?.type === GROUP ? "?type=group" : "";
      const chatUrl = getSocketUrl(`chats/${authParams}${queryParams}`);

      websocketRef.current = new WebSocket(
        chatUrl
        //    null, {
        //   headers: { authorization: `Bearer ${authToken}` },
        // }
      );

      websocketRef.current.onopen = () => {
        console.log("WebSocket connected!");
      };

      // Listen for messages
      websocketRef.current.onmessage = (event: any) => {
        const socketData = JSON.parse(event.data);

        // Update the message state with the received data
        const chatsData = socketData?.data;
        const isSingle = socketData?.type === "single";
        const isList = socketData?.type === "list";
        const count = socketData?.count;
        setCount(count);

        if (chatsData.length > 0 && isList) {
          // console.log(chatsData);

          if (reconnect) {
            setChats(chatsData);
          } else {
            setChats((prev: any) => [...prev, ...chatsData]);
          }
        } else if (isSingle) {
          setChats((prev: any[]) => {
            // Find the index of the chat with the same id
            const index = prev?.findIndex(
              (chat: any) => chat.id === chatsData.id
            );
            if (index !== -1) {
              if (prev[index].last_message?.id === chatsData.last_message?.id) {
                const newData = [...prev].map((chat, idx) => {
                  if (idx === index) return chatsData;
                  return chat;
                });

                return newData;
              } else {
                // Remove the existing chat and add the updated one to the top
                const updatedChats = prev.filter(
                  (_: any, i: any) => i !== index
                );
                return [chatsData, ...updatedChats]; // Place the updated chat at the top
              }
            } else {
              return [chatsData, ...prev] as any; // Add new chat
            }
          });
        }
        setLoading(false);
      };

      websocketRef.current.onerror = (error: any) => {
        console.error("WebSocket error:", error);
      };

      // Handle errors
      websocketRef.current.onclose = () => {
        setLoading(false);
        console.log("WebSocket is closed now.");
        // return;
        // setIsConnected(false);
        // Reconnect after a delay
        setTimeout(() => {
          console.log("Reconnecting...");
          openWebSocket({ reconnect: true });
        }, 3000); // 3-second delay before reconnecting
      };
    }
  };

  const closeWebSocket = () => {
    if (websocketRef.current) {
      websocketRef.current.close();
    }
  };

  useEffect(() => {
    openWebSocket();
    return () => closeWebSocket();
  }, []);

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );

  if (!chats || chats?.length === 0)
    return (
      <Wrapper>
        <EmptyView />
      </Wrapper>
    );

  return (
    <Wrapper className="whiteBg h100 flexColumn">
      {/* HEADER */}
      <div className="flexColumn borderBottom" style={{ paddingInline: 15 }}>
        <div className="flexNullCenter gap15 mt5">
          {/* <CommentSVG /> */}
          <div className="bold flexGrow font18">Chats</div>
          {/* END */}
          <OptionsMenu
            items={[
              {
                label: "New Message",
                icon: <CommentRounded />,
              },
              {
                label: "Create Group",
                icon: <UsersSVG />,
              },
            ]}
          />
        </div>
        <Input
          className="font13 lightBg mt5"
          placeholder="Search"
          prefix={<SearchRounded fontSize="small" sx={{ fill: "grey" }} />}
        />
        <div className="flex gap10" style={{ paddingBlock: 10 }}>
          <Chip label="All" size="small" sx={{ fontSize: 11 }} />
          <Chip label="Groups" size="small" sx={{ fontSize: 11 }} />
        </div>
      </div>
      {/* LIST */}
      <div className="scroll flexGrow">
        {chats?.map((val, i) => (
          <ChatItem
            data={val}
            key={i}
            onClick={setChat}
            selected={chat?.id === val?.id}
          />
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 350px;
  min-width: 350px;
  border-right: 1px solid #eee;
`;
