import styled from "styled-components";
import { formatPhoneNumber } from "../../../utils/helperUtils";
import { GROUP } from "../../../constants/constants";
import { AvatarView } from "../../common/AvatarView";
import { GroupDetails } from "./GroupDetails";
import { useState } from "react";
import moment from "moment";

export const ChatDetails = ({ chat }: { chat: any }) => {
  const [details, setDetails] = useState<any>();
  const { created_at, members = 0 } = details || {};
  const { title, isGroup, isThirdParty, phone_number, type } = chat || {};
  // const { participants } = data || {};

  const subtitle = isGroup
    ? `${GROUP} - ${members} members`
    : formatPhoneNumber(phone_number);

  return (
    <Wrapper className="h100">
      {/* MID */}
      <div className="flexCenter p20 flexColumn gap15">
        <AvatarView text={title} size={100} type={type} />
        <div className="textCenter">
          <div className="semiBold">{title}</div>
          <div className="greyColor font13 mt5 capitalize">{subtitle}</div>
        </div>
      </div>
      <SectionItem
        visible={!isGroup && !isThirdParty}
        data={{ label: "Last Seen", value: "Last seen at 12:00" }}
      />
      <SectionItem
        data={{
          label: "Created At",
          value: `Created at ${moment(created_at).format("DD/MM/YY, HH:mm")}`,
        }}
      />

      {/* GROUP */}
      {isGroup && <GroupDetails chat={chat} setDetails={setDetails} />}
    </Wrapper>
  );
};

const SectionItem = ({
  data,
  visible = true,
}: {
  data: any;
  visible?: boolean;
}) => {
  if (!visible) return <></>;
  const { label, value } = data || {};
  return (
    <div
      className=""
      style={{ borderTop: "5px solid #f2f2f2", padding: "15px 15px" }}
    >
      <div className="semiBold font13">{label}</div>
      <div className="mt5 font12 greyColor">{value}</div>
    </div>
  );
};
const Wrapper = styled.div``;
