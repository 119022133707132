import { CURRENT_COLOR } from "../../../constants/constants";

export const UsersSVG = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={CURRENT_COLOR}
      width={24}
      height={24}
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M256 271.5c38.5 0 69.8-31.3 69.8-69.8 0-38.6-31.3-69.8-69.8-69.8-38.6 0-69.8 31.3-69.8 69.8s31.2 69.8 69.8 69.8zM411.2 209.5c38.5 0 69.8-31.3 69.8-69.8 0-38.6-31.3-69.8-69.8-69.8-38.6 0-69.8 31.3-69.8 69.8-.1 38.5 31.2 69.8 69.8 69.8zM403.2 239.9c-4.7 0-9.4.3-14.1.9-29.2 3.9-55.5 19.8-72.5 43.9 34.8 15.8 62.1 44.4 76.2 79.9h103.6c8.6 0 15.5-6.9 15.5-15.5v-.6c0-60-48.7-108.7-108.7-108.6zM256 302.5c-64.3 0-116.4 52.1-116.4 116.4v7.8c0 8.6 6.9 15.5 15.5 15.5h201.7c8.6 0 15.5-6.9 15.5-15.5v-7.8c.1-64.3-52-116.4-116.3-116.4zM195.4 284.7c-17-24.1-43.2-40-72.5-43.9-4.7-.6-9.4-.9-14.1-.9C48.8 239.8.1 288.5 0 348.5v.6c0 8.6 6.9 15.5 15.5 15.5h103.6c14.2-35.5 41.5-64.1 76.3-79.9zM100.8 209.5c38.6 0 69.8-31.3 69.8-69.8 0-38.6-31.3-69.8-69.8-69.8S31 101.1 31 139.6c.1 38.6 31.3 69.8 69.8 69.9z" />
    </svg>
  );
};
