import { Image } from "antd";
import { LogoSVGImg } from "../../assets/svg/Svgs";
import { DEFAULT_IMAGE_WIDTH } from "../../constants/constants";
export const ChatImageViewer = ({ images }: { images?: any[] }) => {
  const previewUrl = images?.[0];
  return (
    <Image.PreviewGroup items={images}>
      <div
        className="radius5"
        style={{
          overflow: "hidden",
          width: DEFAULT_IMAGE_WIDTH,
          height: DEFAULT_IMAGE_WIDTH,
        }}
      >
        <Image
          height="100%"
          width="100%"
          style={{
            objectFit: "cover",
          }}
          src={previewUrl}
          fallback={LogoSVGImg}
        />
      </div>
    </Image.PreviewGroup>
  );
};
