import styled from "styled-components";
import { ChatsList } from "./ChatsList";
import { MessagesPage } from "../messages/MessagesPage";
import { ChatDetails } from "./ChatDetails";
import { AsideView, useAsideView } from "../../layouts/AsideView";
import { useState } from "react";
import { CommentSVG } from "../../../assets/svg/icons/CommentSVG";
import { alpha } from "@mui/material";
import { APP_NAME } from "../../../constants/constants";
import { Button } from "antd";
import { primaryColor } from "../../../constants/theme";

export const ChatsPage = () => {
  const { open, openViewer, closeViewer } = useAsideView({ open: true });
  const [chat, setChat] = useState<any>();

  //   console.log("OPEN", open);
  //   console.log("CHAT", chat?.id);

  return (
    <Wrapper className="flex h100">
      {/* LEFT */}
      <ChatsList setChat={setChat} chat={chat} />
      {chat ? (
        <>
          {/* MID */}
          <ContentWrapper className="flexGrow">
            <MessagesPage openViewer={openViewer} chat={chat} key={chat?.id} />
          </ContentWrapper>
          {/* RIGHT */}
          <AsideView
            open={open}
            title={chat?.isGroup ? "Group Info" : "Contact Info"}
            closeViewer={closeViewer}
            width={"330px"}
          >
            <ChatDetails chat={chat} />
          </AsideView>
        </>
      ) : (
        <div className="flexColumn flexGrow flexCenter h100 textCenter whiteBg">
          <div className="flexGrow flexCenter flexColumn gap15">
            <div
              className="p20 radius15"
              style={{ backgroundColor: alpha(primaryColor, 0.1) }}
            >
              <CommentSVG width={40} height={40} fill={primaryColor} />
            </div>
            <div className="semiBold font18">Messages will appear here</div>
            <div className="font13 greyColor">
              Download <b>{APP_NAME}</b> for Android to have a better
              experience.
            </div>
            <Button type="primary" className="font13 radius10">
              Download App
            </Button>
          </div>
          <div className="greyColor font12" style={{ marginBottom: 50 }}>
            Your personal messages are end-to-end encrypted
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const ContentWrapper = styled.div``;
