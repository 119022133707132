import { KeyboardArrowDown, Person, Settings } from "@mui/icons-material";
import styled from "styled-components";
import { LogoSVG } from "../../assets/svg/Svgs";
import { Dropdown } from "antd";
import { APP_NAME } from "../../constants/constants";
import { AvatarView } from "./AvatarView";
import { alpha } from "@mui/material";
import { primaryColor } from "../../constants/theme";
import { useAuth } from "../context/AuthContext";

export const Appbar = () => {
  const { logout } = useAuth();
  const items = [
    {
      key: "1",
      label: "Profile",
      icon: <Person />,
    },
    {
      key: "2",
      label: "Logout",
      onClick: logout,
      icon: <Settings />,
    },
  ];
  return (
    <AppBarWrapper className="flexNullCenter gap20 borderBottom whiteBg">
      {/* START */}
      <LogoSVG width={30} height={30} />
      {/* MID */}
      <div className="flexGrow">
        <h3>{APP_NAME}</h3>
      </div>
      {/* END */}
      <Dropdown
        menu={{
          items,
        }}
        placement="bottomRight"
      >
        <div
          className="flexNullCenter gap5 p5 radius20"
          style={{ backgroundColor: alpha(primaryColor, 0.15) }}
        >
          <AvatarView text={"U"} />
          <KeyboardArrowDown fontSize="small" />
        </div>
      </Dropdown>
      {/* <IconButton>
        <MoreVert />
      </IconButton> */}
    </AppBarWrapper>
  );
};

const AppBarWrapper = styled.div`
  height: 60px;
  padding: 0 20px;
  left: 0;
  right: 0;
  @media (max-width: 960px) {
  }
`;
