import styled from "styled-components";
import { APP_EMAIL, APP_NAME } from "../../../constants/constants";

export const PrivacyPolicy = () => {
  const email = <a href={`mailto:${APP_EMAIL}`}>{APP_EMAIL}</a>;
  return (
    <Wrapper>
      <h1>PRIVACY POLICY</h1>
      <b>Last updated October 10th,</b>
      <p>
        2024 This privacy notice for {APP_NAME} ('we', 'us', or 'our'),
        describes how and why we might collect, store, use, and/or share
        ('process') your information when you use our services ('Services'),
        such as when you:
      </p>
      <p>
        ▪ Download and use our mobile application ({APP_NAME}), or any other
        application of ours that links to this privacy notice
      </p>
      <p>
        ▪ Engage with us in other related ways, including any sales, marketing,
        or events
      </p>
      <b>Questions or concerns?</b>
      <p>
        Reading this privacy notice will help you understand your privacy rights
        and choices. If you do not agree with our policies and practices, please
        do not use our Services. If you still have any questions or concerns,
        please contact us at {email}.
      </p>

      <h3>SUMMARY OF KEY POINTS</h3>
      <p>
        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>

        <p>
          <b>What personal information do we process?</b> When you visit, use,
          or navigate our Services, we may process personal information
          depending on how you interact with us and the Services, the choices
          you make, and the products and features you use. Learn more about
          personal information you disclose to us.
        </p>

        <p>
          <b>Do we process any sensitive personal information?</b> We do not
          process sensitive personal information.
        </p>

        <p>
          <b>Do we collect any information from third parties?</b> We do not
          collect any information from third parties.
        </p>

        <p>
          <b>How do we process your information?</b> We process your information
          to provide, improve, and administer our Services, communicate with
          you, for security and fraud prevention, and to comply with law. We may
          also process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so. Learn more about how we process your information.
        </p>

        <p>
          <b>
            In what situations and with which parties do we share personal
            information?
          </b>{" "}
          We may share information in specific situations and with specific
          third parties. Learn more about when and with whom we share your
          personal information.
        </p>

        <p>
          <b> How do we keep your information safe?</b> We have organisational
          and technical processes and procedures in place to protect your
          personal information. However, no electronic transmission over the
          internet or information storage technology can be guaranteed to be
          100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about how we keep your information
          safe.
        </p>

        <p>
          <b>What are your rights?</b> Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Learn more about your
          privacy rights.
        </p>

        <p>
          <b>How do you exercise your rights?</b> The easiest way to exercise
          your rights is by submitting a data subject access request, or by
          contacting us. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </p>

        <p>
          Want to learn more about what we do with any information we collect?
          Review the privacy notice in full.
        </p>
      </p>
      <h3>TABLE OF CONTENTS</h3>
      <div>1. WHAT INFORMATION DO WE COLLECT?</div>
      <div>2. HOW DO WE PROCESS YOUR INFORMATION? </div>
      <div>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</div>
      <div>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </div>
      <div>5. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
      <div>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
      <div>7. DO WE COLLECT INFORMATION FROM MINORS?</div>
      <div>8. WHAT ARE YOUR PRIVACY RIGHTS?</div>
      <div>9. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
      <div>10. DO WE MAKE UPDATES TO THIS NOTICE?</div>
      <div>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
      <div>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </div>
      <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
      <p>
        Personal information you disclose to us In Short: We collect personal
        information that you provide to us. We collect personal information that
        you voluntarily provide to us when you register on the Services, express
        an interest in obtaining information about us or our products and
        Services, when you participate in activities on the Services, or
        otherwise when you contact us.
        <p>
          <b> Personal Information Provided by You.</b>
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
          {[
            "names",
            "phone numbers",
            "email addresses",
            "mailing addresses",
            "usernames",
            "contact preferences",
          ].map((item) => (
            <li>{item}</li>
          ))}
        </p>
        <p>
          <b>Sensitive Information.</b> We do not process sensitive information.
        </p>
        <p>
          <b>Payment Data.</b>We may collect data necessary to process your
          payment if you choose to make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is handled and stored by Flutterwave. You
          may find their privacy notice link(s) here:
          https://flutterwave.com/ug/privacy-policy.
        </p>
        <p>
          <b>Social Media Login Data.</b> We may provide you with the option to
          register with us using your existing social media account details,
          like your Facebook, X, or other social media account. If you choose to
          register in this way, we will collect certain profile information
          about you from the social media provider, as described in the section
          called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
        </p>
        <p>
          <b>Application Data</b>. If you use our application(s), we also may
          collect the following information if you choose to provide us with
          access or permission:
        </p>
        <li>
          Push Notifications. We may request to send you push notifications
          regarding your account or certain features of the application(s). If
          you wish to opt out from receiving these types of communications, you
          may turn them off in your device's settings.
        </li>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h4>Information automatically collected</h4>
        <p>
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services. We automatically collect
          certain information when you visit, use, or navigate the Services.
          This information does not reveal your specific identity (like your
          name or contact information) but may include device and usage
          information, such as your IP address, browser and device
          characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when
          you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        The information we collect includes:
        <li>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use our Services and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in the Services (such as the date/time
          stamps associated with your usage, pages and files viewed, searches,
          and other actions you take such as which features you use), device
          event information (such as system activity, error reports (sometimes
          called 'crash dumps'), and hardware settings).
        </li>
        <li>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
        </li>
      </p>
      <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
      <p>
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent. We process your personal
        information for a variety of reasons, depending on how you interact with
        our Services, including: ▪ To facilitate account creation and
        authentication and otherwise manage user accounts. We may process your
        information so you can create and log in to your account, as well as
        keep your account in working order. ▪ To deliver and facilitate delivery
        of services to the user. We may process your information to provide you
        with the requested service. ▪ To respond to user inquiries/offer support
        to users. We may process your information to respond to your inquiries
        and solve any potential issues you might have with the requested
        service. ▪ To fulfil and manage your orders. We may process your
        information to fulfil and manage your orders, payments, returns, and
        exchanges made through the Services.
      </p>
      <h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
      <p>
        In Short: We may share information in specific situations described in
        this section and/or with the following third parties. We may need to
        share your personal information in the following situations: ▪ Business
        Transfers. We may share or transfer your information in connection with,
        or during negotiations of, any merger, sale of company assets,
        financing, or acquisition of all or a portion of our business to another
        company.
      </p>
      <h3>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
      <p>
        In Short: If you choose to register or log in to our Services using a
        social media account, we may have access to certain information about
        you. Our Services offer you the ability to register and log in using
        your third-party social media account details (like your Facebook or X
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform. We will use the information
        we receive only for the purposes that are described in this privacy
        notice or that are otherwise made clear to you on the relevant Services.
        Please note that we do not control, and are not responsible for, other
        uses of your personal information by your third-party social media
        provider. We recommend that you review their privacy notice to
        understand how they collect, use, and share your personal information,
        and how you can set your privacy preferences on their sites and apps.
      </p>
      <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
      <p>
        In Short: We keep your information for as long as necessary to fulfil
        the purposes outlined in this privacy notice unless otherwise required
        by law. We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us. When we have no ongoing
        legitimate business need to process your personal information, we will
        either delete or anonymise such information, or, if this is not possible
        (for example, because your personal information has been stored in
        backup archives), then we will securely store your personal information
        and isolate it from any further processing until deletion is possible.
      </p>
      <h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
      <p>
        In Short: We aim to protect your personal information through a system
        of organisational and technical security measures. We have implemented
        appropriate and reasonable technical and organisational security
        measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your
        information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot
        promise or guarantee that hackers, cybercriminals, or other unauthorised
        third parties will not be able to defeat our security and improperly
        collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal
        information to and from our Services is at your own risk. You should
        only access the Services within a secure environment.
      </p>
      <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
      <p>
        In Short: We do not knowingly collect data from or market to children
        under 18 years of age. We do not knowingly collect, solicit data from,
        or market to children under 18 years of age, nor do we knowingly sell
        such personal information. By using the Services, you represent that you
        are at least 18 or that you are the parent or guardian of such a minor
        and consent to such minor dependent’s use of the Services. If we learn
        that personal information from users less than 18 years of age has been
        collected, we will deactivate the account and take reasonable measures
        to promptly delete such data from our records. If you become aware of
        any data we may have collected from children under age 18, please
        contact us at {email}.
      </p>
      <h3>8.WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      <p>
        In Short: You may review, change, or terminate your account at any time,
        depending on your country, province, or state of residence. Withdrawing
        your consent: If we are relying on your consent to process your personal
        information, which may be express and/or implied consent depending on
        the applicable law, you have the right to withdraw your consent at any
        time. You can withdraw your consent at any time by contacting us by
        using the contact details provided in the section 'HOW CAN YOU CONTACT
        US ABOUT THIS NOTICE?' below. However, please note that this will not
        affect the lawfulness of the processing before its withdrawal nor, when
        applicable law allows, will it affect the processing of your personal
        information conducted in reliance on lawful processing grounds other
        than consent.
      </p>
      <h3>Account Information</h3>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
        <li>Log in to your account settings and update your user account.</li>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at {email}.
        </p>
      </p>
      <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ('DNT') feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage, no
        uniform technology standard for recognising and implementing DNT signals
        has been finalised. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
      <h3>10. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws. We may update this privacy notice from time to time.
        The updated version will be indicated by an updated 'Revised' date at
        the top of this privacy notice. If we make material changes to this
        privacy notice, we may notify you either by prominently posting a notice
        of such changes or by directly sending you a notification. We encourage
        you to review this privacy notice frequently to be informed of how we
        are protecting your information.
      </p>
      <h3>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
      <p>
        If you have questions or comments about this notice, you may contact us
        by post at: YOYA Technologies Ntinda, Ministers Village, Kampala, Uganda
      </p>
      <h3>
        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h3>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, details
        about how we have processed it, correct inaccuracies, or delete your
        personal information. You may also have the right to withdraw your
        consent to our processing of your personal information. These rights may
        be limited in some circumstances by applicable law. To request to
        review, update, or delete your personal information, please fill out and
        submit a data subject access request.
      </p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 60%;
  justify-self: center;
  padding: 30px;
  overflow: scroll;
  height: 100vh;
  @media (max-width: 960px) {
    max-width: 100%;
  }
`;
