import { Badge } from "antd";
import styled from "styled-components";
import { GROUP, THIRD_PARTY } from "../../../constants/constants";
import { AvatarView } from "../../common/AvatarView";
import { formatPhoneNumber, getTimestamp } from "../../../utils/helperUtils";

export const ChatItem = ({
  data,
  onClick,
  selected,
}: {
  data: any;
  onClick?: any;
  selected?: any;
}) => {
  const { name, type, last_message, counter_participant, unread_count, id } =
    data || {};
  const { content } = last_message || {};
  const { username, phone_number } = counter_participant || {};
  const { created_by } = last_message || {};
  const isGroup = type === GROUP;
  const isThirdParty = created_by?.type === THIRD_PARTY;
  const chatName = isGroup ? name : username || formatPhoneNumber(phone_number);
  const chatType = isThirdParty ? THIRD_PARTY : type;

  const itemData = {
    id,
    title: chatName,
    type: chatType,
    isGroup,
    isThirdParty,
    phone_number,
    data,
  };

  return (
    <Wrapper
      className="pointer animate p15 flexNullCenter gap15 borderBottom"
      selected={selected}
      onClick={() => onClick(itemData)}
    >
      <AvatarView text={chatName} size={40} type={chatType} />
      {/* MID */}
      <div className="flexColumn gap5 flexGrow">
        <div className="semiBold" style={{ fontSize: 14 }}>
          {chatName}
        </div>
        {/* MESSAGE */}
        <div className="greyColor font12 ellipsis">
          {isGroup && (
            <span className="semiBold">
              {created_by?.username?.split(" ")?.[0] || created_by?.username}:{" "}
            </span>
          )}
          {content || "No message"}
        </div>
      </div>
      {/* END */}
      <div className="h100 flexColumn gap5 flexEnd">
        <div className="greyColor font11" style={{ whiteSpace: "nowrap" }}>
          {getTimestamp(last_message?.created_at)}
        </div>
        <Badge count={unread_count} style={{ fontSize: 12 }} />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<any>`
  background-color: ${(props) => props?.selected && "#f2f2f2"};
  height: 40px;
  &:hover {
    background-color: #f2f2f2;
  }
`;
