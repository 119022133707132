import { cloneElement, createContext, useContext, useState } from "react";

export const GlobalContext = createContext({});

export const useGlobalContext = () => useContext(GlobalContext);

let updateGlobalContext;

export const GlobalProvider = ({ children }: any) => {
  const [data, setData] = useState();
  const renderBody = (ele: any) => cloneElement(ele);

  updateGlobalContext = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
  };

  const contextValue = { data };

  return (
    <GlobalContext.Provider value={contextValue}>
      {renderBody(children)}
    </GlobalContext.Provider>
  );
};

export { updateGlobalContext };
