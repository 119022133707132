import { useState } from "react";

function useForm(keys?: any) {
  const [form, setForm] = useState(keys || {});
  const [changes, setChanges] = useState();

  const validateForm = (validators = [], setError: any) => {
    let errors: any = null;

    let isValid = true;
    validators?.forEach(
      ({ notValid, field, value, required, visible = true }) => {
        if (notValid) {
          errors = {
            ...(errors || {}),
            [field]: notValid,
          };
          isValid = false;
        }

        if (required && !form[field] && visible && !value) {
          errors = {
            ...(errors || {}),
            [field]: "This field is required",
          };
          isValid = false;
        }
      }
    );

    if (errors != null)
      errors = { ...errors, message: "Missing required fields" };

    setError(errors);

    return isValid;
  };

  const updateFormValue = (key: any, value: any) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateFormValues = (obj: any) => {
    setForm((prevState: any) => ({
      ...prevState,
      ...obj,
    }));
  };

  const updateChanges = (obj: any) => {
    setChanges((prevState: any) => ({ ...prevState, ...obj }));
  };

  function clearForm() {
    setForm({});
  }

  const onChange = (event: any) => {
    // Get the name of the field that caused this change event
    // Get the new value of this field
    const { name, value } = event.target;

    // check if the field is int
    // var newValue = parseInt(value) ? parseInt(value) : value;

    updateFormValue(name, value);
  };

  return {
    form,
    setForm,
    clearForm,
    updateFormValue,
    updateFormValues,
    onChange,
    validateForm,
    changes,
    updateChanges,
    setChanges,
  };
}

export default useForm;
