import { useState } from "react";
import useAxios from "../axios/useAxios";

const useSet = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();
  const { set } = useAxios();

  const uploadData = ({
    api,
    params,
    method,
    type,
    id,
    onSuccess,
  }: {
    api?: any;
    params?: any;
    method?: any;
    type?: any;
    id?: any;
    onSuccess?: any;
  }) => {
    const extras = {};

    // If id is passed get single instance, other fetch list
    setError(null);
    setSuccess(false);
    setSubmitting(true);
    set({
      api: id ? `${api}${id}/` : api,
      loader: setSubmitting,
      errorHandler: setError,
      successHandler: (res: any) => {
        setData(res);
        setSuccess(true);
        onSuccess && onSuccess(res);
      },
      methodName: method || (id ? "PATCH" : "POST"),
      params: { ...params, ...extras },
      type,
    });
  };

  const updateErrors = (err: any) => {
    setError(err ? { ...error, ...err } : null);
  };

  return {
    submitting,
    error,
    data,
    success,
    uploadData,
    updateErrors,
    setError,
    setSuccess,
  };
};

export default useSet;
