import { useEffect, useState } from "react";
import { GenericListItem } from "../../elements/Elements";
import useGet from "../../../hooks/common/useGet";
import { API_CHATS } from "../../../constants/apis";
import { LoaderOnly } from "../../common/Loader";
import { formatPhoneNumber } from "../../../utils/helperUtils";

export const GroupDetails = ({
  chat,
  setDetails,
}: {
  chat: any;
  setDetails?: any;
}) => {
  const { id: chatId } = chat || {};
  const [participants, setParticipants] = useState<any>([]);
  const { loadData, loading } = useGet();

  useEffect(() => {
    if (chatId) {
      loadData({
        api: API_CHATS,
        id: chatId,
        onSuccess: (data: any) => {
          setParticipants(data?.participants_details);
          if (setDetails)
            setDetails({
              created_at: data?.created_at,
              members: data?.participants_details?.length,
            });
        },
      });
    }
  }, [chatId]);

  const options = (data: any) => {
    return [
      { label: "Send Message" },
      { label: "Make Admin" },
      { label: "Remove" },
    ];
  };

  if (loading) return <LoaderOnly />;

  return (
    <div className="flexColumn" style={{ borderTop: "5px solid #f2f2f2" }}>
      <div className="semiBold font14 p15 borderBottom">Participants</div>
      <div className="flexColumn">
        {participants.map((val: any, i: any) => {
          const { is_admin, user } = val || {};
          const { phone_number, username } = user || {};
          const data = {
            ...val,
            title: username,
            subtitle: formatPhoneNumber(phone_number),
            tag: { visible: is_admin, label: "Admin", color: "blue" },
          };
          return <GenericListItem data={data} key={i} options={options(val)} />;
        })}
      </div>
    </div>
  );
};
