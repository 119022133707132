import { Input } from "antd";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import {
  Add,
  DescriptionRounded,
  EmojiEmotions,
  ImageRounded,
  Send,
} from "@mui/icons-material";
import { MessageItem } from "./MessageItem";
import { OptionsMenu, ScrollToBottom } from "../../elements/Elements";
import { MgBgSVG } from "../../../assets/svg/Svgs";
import { AvatarView } from "../../common/AvatarView";
import { ReplyBubble } from "./ReplyBubble";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { API_CHATS, API_MESSAGES, getSocketUrl } from "../../../constants/apis";
import {
  FORMDATA,
  GROUP,
  ONLINE,
  ONLINE_STATUS,
  SYSTEM,
} from "../../../constants/constants";
import {
  cleanNewMessages,
  getLastSeen,
  returnObject,
  updateUserMessage,
} from "../../../utils/helperUtils";
import { Loader } from "../../common/Loader";
import useForm from "../../../hooks/common/useForm";
import useSet from "../../../hooks/common/useSet";

export const MessagesPage = ({
  openViewer,
  chat,
}: {
  openViewer: any;
  chat: any;
}) => {
  const { id, title, type, recipient_id } = chat || {};
  const [chatId, setChatId] = useState<string>(id);
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const scrollRef = useRef<any>(null);
  const { user, authToken } = useAuth();
  const userId = user?.id;
  const [loading, setLoading] = useState(false);
  const authParams = `?token=${authToken}`;
  const [online, setOnline] = useState(false);
  const [lastSeen, setLastSeen] = useState<any>();
  const [socket, setSocket] = useState<WebSocket>();
  const [count, setCount] = useState(0);
  const [incomingMessage, setIncomingMessage] = useState();
  const [messages, setMessages] = useState<any>([]);
  const [reversedList, setReversedList] = useState<any>([]);
  const [firstMessage, setFirstMessage] = useState(false);
  const { form, onChange, setForm } = useForm();
  const { uploadData } = useSet();
  const [sending, setSending] = useState<any>([]);
  const [page, setPage] = useState(2);

  const isGroup = type === GROUP;

  // RESET SELECTED MESSAGE
  const clearSelectedMessage = () => setSelectedMessage(null);

  // LOAD MORE
  const loadMore = () => {
    if (messages?.length < count) {
      // console.log('loadMoreData', page);
      socket?.send(
        JSON.stringify({
          page,
        })
      );
      setPage((prev) => prev + 1);
    }
    console.log("LOAD MORE");
  };
  // SCROLL
  const handleScroll = () => {
    if (!scrollRef.current || loading) return;

    const { scrollTop } = scrollRef.current;
    if (scrollTop === 0) {
      // Load more messages when scrolled to top
      loadMore();
    }
  };

  // reverse message list
  useEffect(() => {
    const reversed = [...messages]?.slice()?.reverse();
    setReversedList(reversed);
  }, [messages]);

  // handle local sending status
  useEffect(() => {
    if (sending?.includes(incomingMessage)) {
      setSending([]);
    }
  }, [incomingMessage]);

  // GET DESTINATION OF MESSAGE
  const getRecipient = () => {
    const recepient =
      chatId || isGroup ? { chat: chatId } : { recipient: recipient_id };
    return recepient;
  };

  const getChatStatus = () => {
    if (online) return ONLINE;
    if (lastSeen) return getLastSeen(lastSeen);
    return "Click to show more info";
  };

  // SEND MESSAGE
  const handleSendMessage = ({ attachments, message }: any) => {
    const content = (message || form?.message)?.trim();

    if (content) {
      // change sending status
      setSending([...sending, content]);

      // CONSTRUCT PAYLOAD
      const payload = {
        content,
        ...getRecipient(),
        // ...prepareAttachmentsPost([attachments], "attachments"),
        // reply to message
        ...returnObject(selectedMessage?.id, {
          replied_message: selectedMessage?.id,
        }),
        // ...returnObject(forwardedMessage, { forwarded: true }),
      };

      uploadData({
        api: API_MESSAGES,
        params: payload,
        type: attachments && FORMDATA,
        onSuccess: (data: any) => {
          // set chat id from sent message
          if (!chatId && data?.chat) {
            if (!firstMessage) setFirstMessage(true);
            setChatId(data?.chat);
          }
        },
      });

      setMessages((prev: any) => [
        {
          created_by: { id: userId },
          content,
          created_at: new Date(),
        },
        ...prev,
      ]);
      setForm(null);
    }

    //   const content = (message || form?.message)?.trim();

    //   const payload = {
    //     content,
    //     ...getRecipient(),
    //     ...prepareAttachmentsPost([attachments], 'attachments'),
    //     ...returnObject(isGroup, {chat: chatId}),
    //     // reply to message
    //     ...returnObject(selectedMessage?.id, {
    //       replied_message: selectedMessage?.id,
    //     }),
    //     ...returnObject(forwardedMessage, {forwarded: true}),
    //   };

    //   if (content) {
    //     setSending([...sending, content]);
    //     clearSelectedMessage();

    //     uploadData({
    //       api: API_MESSAGES,
    //       params: payload,
    //       type: attachments && FORMDATA,
    //       onSuccess: (data: any) => {
    //         // set chat id from sent message
    //         if (!chatId && data?.chat) {
    //           if (!firstMessage) setFirstMessage(true);
    //           setChatId(data?.chat);
    //         }
    //       },
    //     });

    //     const viewAttachments = attachments?.file?.map((file: any) => ({
    //       file: file?.uri,
    //       caption: attachments?.caption,
    //       ...returnObject(attachments?.document_type === 'letter', {
    //         caption: file?.name,
    //         extention: file?.name,
    //       }),
    //       isViewable: true,
    //     }));

    //     setMessages((prev: any) => [
    //       {
    //         created_by: {id: user_id},
    //         content,
    //         created_at: new Date(),
    //         attachments: viewAttachments,
    //       },
    //       ...prev,
    //     ]);
    //     setForm(null);
    //     scrollToBottom();
    //   }
  };

  // MESSAGES SOCKET
  useEffect(() => {
    // return;

    if (chatId) {
      // const savedValue = getData(chatId);
      // if (savedValue) {
      //   updateFormValues({ message: savedValue });
      // }

      const socketUrl = getSocketUrl(
        `${API_CHATS}${chatId}/${API_MESSAGES}${authParams}`
      );

      const ws = new WebSocket(socketUrl);

      setLoading(true);

      // Connection opened
      ws.onopen = () => {
        setLoading(false);
        setSocket(ws);
      };

      // Listen for messages
      ws.onmessage = (event) => {
        const socketData = JSON.parse(event.data);
        const type = socketData?.type;
        const count = socketData?.count;
        const newData = socketData?.data;

        if (type === ONLINE_STATUS) {
          setLastSeen(socketData.last_seen);
          setOnline(socketData.is_online);
        }

        // console.log('socket data: ', socketData);

        // MESSAGE LIST
        // const message: any = newData[0];
        if (type === "list" && newData?.length > 0) {
          setCount(count);
          // FIRST MESSAGE
          if (newData?.length === 1 && firstMessage) {
            setIncomingMessage(newData?.[0]?.content);
            return;
          }

          // set messages
          setMessages((prev: any) => [
            ...prev,
            ...cleanNewMessages(newData, prev),
          ]);
        } else if (type === "single") {
          // set system messages
          if (newData.type === SYSTEM) {
            return setMessages((prev: any) => [newData, ...prev]);
          }

          // append new data to exisitng message
          setIncomingMessage(newData?.content);

          if (newData?.created_by?.id === userId) {
            return setMessages((prev: any) => [
              ...updateUserMessage(newData, prev),
            ]);
          }
          // SINGLE MESSAGE
          setMessages((prev: any) => [newData, ...prev]);
        }
      };

      // Handle errors
      ws.onerror = (error) => {
        setLoading(false);
        console.error("WebSocket error: ", error);
      };

      // Connection closed
      ws.onclose = (event) => {
        console.log("WebSocket connection closed: ", event.reason);
      };
    } else {
      setLoading(false);
    }
  }, [chatId]);

  return (
    <div className="h100 flexColumn">
      <HeaderWrapper className="whiteBg flexNullCenter borderBottom">
        {/* START */}
        <div className="flexNullCenter gap15 x100">
          <AvatarView text={title} size={40} type={type} onClick={openViewer} />
          {/* MID */}
          <div className="flexGrow pointer" onClick={openViewer}>
            <div className="semiBold">{title}</div>
            <div className="greyColor font12 mt5">{getChatStatus()}</div>
          </div>
          {/* END */}
          <div>
            <OptionsMenu
              items={[{ label: "Show Info", onClick: openViewer }]}
            />
          </div>
        </div>
      </HeaderWrapper>
      {/* MID - MESSAGES */}
      <MidWrapper
        className="flexGrow scroll p10"
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {/* MESSAGES */}
        {loading ? (
          <Loader />
        ) : (
          reversedList?.map((val: any, i: any) => {
            return (
              <MessageItem
                data={val}
                key={i}
                index={i}
                userId={user?.id}
                type={type}
                sending={sending?.includes(val?.content)}
                prevMessage={reversedList?.[i - 1]}
                setSelectedMessage={setSelectedMessage}
              />
            );
          })
        )}
        <ScrollToBottom messages={reversedList} />
      </MidWrapper>
      {/* BOTTOM */}

      <BottomWrapper className="whiteBg borderTop">
        <ReplyBubble
          style={{ marginTop: 10 }}
          onClose={clearSelectedMessage}
          data={selectedMessage}
          visible={selectedMessage}
        />
        <div className="flexNullCenter" style={{ minHeight: 60 }}>
          <IconButton size="small">
            <EmojiEmotions />
          </IconButton>

          <OptionsMenu
            placement="topLeft"
            items={[
              {
                label: "Photos",
                icon: <ImageRounded sx={{ fill: "rebeccapurple" }} />,
              },
              {
                label: "Documents",
                icon: <DescriptionRounded sx={{ fill: "darkcyan" }} />,
              },
            ]}
            icon={Add}
          />
          {/* INPUT */}
          <div className="flexGrow p10">
            <Input
              name="message"
              className="lightBg"
              value={form?.message}
              placeholder="Type a message"
              onChange={onChange}
            />
          </div>
          <IconButton onClick={handleSendMessage}>
            <Send />
          </IconButton>
        </div>
      </BottomWrapper>
    </div>
  );
};

const HeaderWrapper = styled.div`
  min-height: 60px;
  padding: 0 20px;
`;
const MidWrapper = styled.div`
  background-image: url(${MgBgSVG});
  background-repeat: repeat;
  background-size: 300px;
`;
const BottomWrapper = styled.div`
  padding: 0 10px;
`;
