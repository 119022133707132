import { Close } from "@mui/icons-material";
import { alpha, IconButton } from "@mui/material";
import { UNKNOWN } from "../../../constants/constants";

export const ReplyBubble = ({ data, onClose, visible, ...props }: any) => {
  const { title = UNKNOWN, content = UNKNOWN } = data || {};
  if (!visible) return null;
  return (
    <div
      className="radius5 flex"
      style={{
        backgroundColor: alpha("#000", 0.05),
        overflow: "hidden",
        ...props.style,
      }}
    >
      <span className="primaryBg" style={{ minWidth: 3 }} />
      <div className="flexGrow p10">
        <div className="font10 semiBold mainColor">{title}</div>
        <div className="font11 mt5 greyColor">{content}</div>
      </div>
      {onClose && (
        <div className="p10 flexCenter">
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};
