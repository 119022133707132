import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  decodeUser,
  getCredentials,
  removeCredentials,
  setCredentials,
} from "../../utils/helperUtils";

const AuthContext = createContext<any>({});

const AuthProvider = ({ children }: any) => {
  const [loggedIn, setLoggedIn] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    const fetchCredentials = () => {
      const res = getCredentials();

      if (res) {
        const token = res.token;
        const user = decodeUser(token);
        setAuthToken(token);
        setUser(user);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    fetchCredentials();
  }, []);

  const login = (credentials: any) => {
    const token = credentials.token;
    setCredentials(credentials);
    setAuthToken(token);
    setUser(decodeUser(token));
    setLoggedIn(true);
  };

  const logout = () => {
    removeCredentials();
    setUser(null);
    setLoggedIn(false);
  };

  const renderBody = (ele: any) => cloneElement(ele);

  return (
    <AuthContext.Provider
      value={{
        login,
        loggedIn,
        // loggedIn: true,
        logout,
        user,
        authToken,
      }}
    >
      {renderBody(children)}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
