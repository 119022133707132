import { Button, Input } from "antd";
import { formatPhoneNumber } from "../../../utils/helperUtils";
import { useState } from "react";
import useSet from "../../../hooks/common/useSet";
import { API_VERIFY_OTP } from "../../../constants/apis";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

export const VerifyOTP = ({ data }: { data?: any }) => {
  const location = useLocation();
  const { phone_number, otp } = location?.state || {};
  const [otpCode, setOtpCode] = useState<any>();
  const { uploadData, submitting } = useSet();
  const { login } = useAuth();

  const onChange = (text: any) => {
    console.log("onChange:", text);
    setOtpCode(text);
  };

  //   const onInput = (value: any) => {
  //     console.log("onInput:", value);
  //   };

  const onSubmit = () => {
    // login({
    //   token: testToken,
    // });

    if (otpCode?.length === 6) {
      uploadData({
        api: API_VERIFY_OTP,
        params: { otp: otpCode, phone_number },
        onSuccess: (data: any) => {
          // Perform Login
          const token = data?.token;
          console.log("TOKEN", token);
          if (token) login({ token });
        },
      });
    }
  };

  return (
    <div className="flexColumn gap20">
      <div className="semiBold font18">
        Verify your <span className="mainColor">Account</span>
      </div>

      <div className="greyColor font13">
        Please enter the verification code sent to{" "}
        <span className="semiBold">{formatPhoneNumber(phone_number)}</span>
      </div>
      <Input.OTP onChange={onChange} variant="filled" />
      <Button type="primary" onClick={onSubmit} loading={submitting}>
        Verify OTP
      </Button>
      <div className="mainColor textCenter font13 pointer">Resend Code</div>
      <div className="textCenter font12 greyColor">
        <span onDoubleClick={() => alert(`OTP Code - ${otp}`)}>OTP</span> code
        will automatically expire in 5 min.
      </div>
    </div>
  );
};
