import { ConfigProvider } from "antd";
import { antdTheme, themeMui } from "./constants/theme";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "./components/context/AuthContext";
import { GlobalProvider } from "./components/context/GlobalContext";
import { MainContainer } from "./components/layouts/MainContainer";

function App() {
  return (
    <>
      <ThemeProvider theme={themeMui}>
        <ConfigProvider theme={antdTheme}>
          <GlobalProvider>
            <BrowserRouter>
              <AuthProvider>
                <MainContainer />
              </AuthProvider>
            </BrowserRouter>
          </GlobalProvider>
        </ConfigProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
