import styled from "styled-components";
import { BUBBLE_PRIMARY_COLOR } from "../../../constants/colors";
import { AccessTime, Check, KeyboardArrowDown } from "@mui/icons-material";
import { ReplyBubble } from "./ReplyBubble";
import { getMessageInfoTag, OptionsMenu } from "../../elements/Elements";
import { ChatImageViewer } from "../../common/ChatImageViewer";
import { getAttachmentImages } from "../../../utils/helperUtils";
import {
  DEFAULT_IMAGE_WIDTH,
  GROUP,
  SYSTEM,
} from "../../../constants/constants";
import moment from "moment";

export const MessageItem = ({
  data,
  // index,
  setSelectedMessage,
  prevMessage,
  userId: currentUser,
  type,
  sending,
}: {
  data: any;
  index: any;
  setSelectedMessage?: any;
  prevMessage?: any;
  userId?: any;
  type?: any;
  sending?: boolean;
}) => {
  const {
    // id,
    content,
    edited,
    forwarded,
    attachments,
    color_code,
    created_by,
    created_at,
    type: message_type,
    replied_message,
    replied_message_object,
  } = data || {};
  const {
    id: authorId,
    username,
    // phone_number
  } = created_by || {};
  const replied_message_username = replied_message_object?.created_by?.username;
  const replied_message_content = replied_message_object?.content;

  const StatusIcon = sending ? AccessTime : Check;
  const hasImages = attachments?.[0]?.caption === "message_attachment";
  // const senderId = authorId;
  const prevId = prevMessage?.created_by?.id;
  const prevIsSystem = prevMessage?.type === SYSTEM;
  const prevDate = prevMessage?.created_at || new Date();
  const groupedMessages = prevId === authorId;
  const isGroup = type === GROUP;
  const isSystem = message_type === SYSTEM;
  const isDifferentDay = !moment(created_at).isSame(moment(prevDate), "day");
  const isCurrentUser = authorId === currentUser;

  // console.log(
  //   "Message:",
  //   content,
  //   "authorId:",
  //   authorId,
  //   "prevId",
  //   prevMessage?.created_by?.id
  // );

  // STYLES
  const senderStyle = { backgroundColor: BUBBLE_PRIMARY_COLOR };
  const recieverStyle = { backgroundColor: "white" };
  const generalStyles = {
    marginTop: prevIsSystem
      ? 10
      : groupedMessages
      ? 2
      : isDifferentDay
      ? 10
      : 2,
  };

  const bubbleStyle = () => {
    const styles = isCurrentUser ? senderStyle : recieverStyle;
    return { ...styles, ...generalStyles };
  };

  if (isSystem)
    return (
      <div className="flexCenter mt10">
        <div
          className="font11 whiteBg greyColor radius5"
          style={{ padding: "5px 10px", borderBottom: "1.5px solid #eee" }}
        >
          {content}
        </div>
      </div>
    );

  return (
    <div>
      {/* DATE HEADER */}
      {isDifferentDay && (
        <div className="flexCenter">
          <div
            className="font11 radius5 whiteBg greyColor"
            style={{
              padding: "5px 10px",
              margin: "10px 0",
              borderBottom: "1.5px solid #eee",
            }}
          >
            {moment(created_at).format("DD MMM YYYY")}
          </div>
        </div>
      )}
      <Wrapper className="flex" currentUser={isCurrentUser}>
        <BubbleWrapper
          className="p10 radius10 whiteBg font13 gap5 flexColumn pointer animate"
          currentUser={isCurrentUser}
          hasImages={hasImages}
          style={bubbleStyle()}
        >
          {/* GROUP USER */}
          {isGroup && !isCurrentUser && (
            <div
              className="semiBold font11 greyColor"
              style={{ color: color_code }}
            >
              {username}
            </div>
          )}
          {/* IMAGES */}
          {hasImages && (
            <div style={{ margin: "0 -5px" }}>
              <ChatImageViewer images={getAttachmentImages(attachments)} />
            </div>
          )}

          {/* MESSAGE INFO TAG */}
          {getMessageInfoTag({ edited, forwarded })}

          {/* REPLY */}
          <ReplyBubble
            style={{ margin: "-5px -5px 0 -5px" }}
            visible={replied_message}
            data={{
              title: replied_message_username,
              content: replied_message_content,
            }}
          />
          <div className="flex gap10" style={{ alignItems: "flex-end" }}>
            {/* MESSAGE TEXT */}
            <div className="flexGrow">{content}</div>
            {/* TIMESTAMP */}
            <div className="font10 greyColor">12:30</div>
            <StatusIcon sx={{ fontSize: 15, marginLeft: -0.8, fill: "grey" }} />
          </div>

          <div id="more" className="animate">
            <OptionsMenu
              icon={KeyboardArrowDown}
              placement="bottomLeft"
              items={[
                {
                  label: "Reply",
                  onClick: () =>
                    setSelectedMessage({ title: username, content }),
                },
                {
                  label: "Edit",
                },
                {
                  label: "Forward",
                },
              ]}
            />
          </div>
        </BubbleWrapper>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{ currentUser?: any }>`
  justify-content: ${(props) => (props?.currentUser ? "flex-end" : "")};
`;

const BubbleWrapper = styled.div<{ currentUser?: any; hasImages?: any }>`
  position: relative;
  max-width: ${({ hasImages }) =>
    hasImages ? `${DEFAULT_IMAGE_WIDTH}px` : "70%"};

  #more {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    #more {
      opacity: 1;
    }
  }
`;
