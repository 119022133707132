import { createTheme } from "@mui/material";

export const primaryColor = "#0a94c5";
export const secondaryColor = "#3a7cc1";

export const themeMui = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: { main: secondaryColor },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          fontSize: 12,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: { borderRadius: 10 },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
});

export const antdTheme = {
  token: {
    colorPrimary: primaryColor,
    secondaryColor: secondaryColor,
  },
  components: {
    Segmented: {
      itemSelectedBg: secondaryColor,
      itemSelectedColor: "white",
    },
    Progress: {
      circleTextColor: "white",
    },
  },
};
