import { BASE_API, BASE_SOCKET_URL } from "./config";

export const getBaseUrl = (url: string) => {
  return `${BASE_API}${url}`;
};

export function getUrl(url: string) {
  return `${BASE_API}${url}`;
}

export function getSocketUrl(url: string) {
  return `${BASE_SOCKET_URL}${url}`;
}

// AUTHENTICATION
export const API_GENERATE_OTP = "accounts/users/otp/";
export const API_VERIFY_OTP = "accounts/users/verify-otp/";

// MESSAGING
export const API_MESSAGES = "messages/";
export const API_CHATS = "chats/";
