import { Edit, MoreVert, StorageRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Dropdown, Tag } from "antd";
import { AvatarView } from "../common/AvatarView";
import styled from "styled-components";
import { ForwardedSVG } from "../../assets/svg/icons/ForwardedSVG";
import { useEffect, useRef } from "react";

export const ErrorText = ({ children }: any) => {
  if (children) return <p className="font12 redColor">{children}</p>;
  return null;
};

export const HelperText = ({ text }: { text: string }) => {
  if (text) return <p className="font11 greyColor thin">{text}</p>;
  return null;
};

export const FormLabel = ({
  children,
  required = false,
  info,
  ...props
}: any) => {
  return (
    <p className="font13 mainColor flexNullCenter gap5 capitalize">
      {children} {required && <span className="redColor">*</span>}
    </p>
  );
};

export const OptionsMenu = ({
  items = [],
  small,
  icon,
  placement,
}: {
  items: any[];
  small?: boolean;
  icon?: any;
  placement?: "bottomLeft" | "topRight" | "topLeft";
}) => {
  const Icon = icon || MoreVert;
  return (
    <Dropdown
      rootClassName="dropdown"
      menu={{
        items,
      }}
      placement={placement || "bottomRight"}
      trigger={["click"]}
    >
      <IconButton size="small">
        <Icon fontSize={small && "small"} />
      </IconButton>
    </Dropdown>
  );
};

export const GenericListItem = ({
  data,
  onClick,
  options,
}: {
  data?: any;
  onClick?: any;
  options?: any;
}) => {
  const { title = "Title", subtitle, tag } = data || {};

  return (
    <Wrapper className="flexNullCenter gap10 pointer" onClick={onClick}>
      {/* START */}
      <AvatarView text={title} size={37} />
      {/* MID */}
      <div className="flexColumn flexGrow" style={{ gap: 5 }}>
        <div className="semiBold font14">{title}</div>
        {subtitle && <div className="greyColor font11">{subtitle}</div>}
      </div>
      {/* END */}
      <div className="flexNullCenter">
        {tag?.visible && (
          <Tag className="font11" color={tag?.color}>
            {tag?.label}
          </Tag>
        )}
        <div id="more" className="animate">
          <OptionsMenu small items={options} />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px 15px;
  #more {
    scale: 0;
    opacity: 0;
  }
  &:hover {
    background-color: #f2f2f2;
    #more {
      scale: 1;
      opacity: 1;
    }
  }
`;

export const getMessageInfoTag = ({
  edited,
  forwarded,
}: {
  edited: any;
  forwarded: any;
}) => {
  function getOptions() {
    let tag,
      icon: any = "";

    if (edited) {
      tag = "Edited";
      icon = Edit;
    } else if (forwarded) {
      tag = "Forwarded";
      icon = ForwardedSVG;
    }
    return { tag, icon };
  }

  const { tag, icon } = getOptions();

  return <>{tag && <MessageInfoTag icon={icon}>{tag}</MessageInfoTag>}</>;
};

export const MessageInfoTag = ({
  children,
  icon: Icon,
}: {
  children: any;
  icon: any;
}) => {
  return (
    <div className="flexNullCenter gap5">
      <Icon style={{ width: 10, height: 10, fill: "grey" }} />
      <div className="font10 greyColor">{children}</div>
    </div>
  );
};

export const EmptyView = () => {
  return (
    <div className="flexCenter flexColumn gap10 h100">
      <StorageRounded sx={{ fontSize: 50 }} />
      <div className=" semiBold">Nothing to show</div>
      <div className="font12 greyColor">
        You currently do not have any chats.
      </div>
    </div>
  );
};

export function ScrollToBottom({ messages }: { messages: any }) {
  // const [initialLoad, setInitalLoad] = useState(false);
  const elementRef = useRef<any>();
  useEffect(() => {
    // if (!initialLoad) {
    elementRef.current.scrollIntoView();
    // setInitalLoad(true);
    // }
  }, [messages]);
  return <div ref={elementRef} />;
}
