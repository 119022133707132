import { LogoSVG } from "../../assets/svg/Svgs";

export const Loader = ({ full }: any) => {
  const size = 40;
  return (
    <div
      className="flexGrow flexCenter x100 h100"
      style={{ height: full && "100vh" }}
    >
      <div className="flexCenter" style={{}}>
        <LogoSVG
          className="pulse"
          width={size}
          height={size}
          style={{ position: "absolute" }}
        />

        <div className="pulse2">
          <div className="loader" />
        </div>
      </div>
    </div>
  );
};

export const LoaderOnly = () => (
  <div className="flexCenter p30">
    <LogoSVG
      className="pulse"
      width={40}
      height={40}
      style={{ position: "absolute" }}
    />

    <div className="pulse2">
      <div className="loader" />
    </div>
  </div>
);
