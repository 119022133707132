import { Button, Input, Select, Space } from "antd";
import useForm from "../../../hooks/common/useForm";
import useSet from "../../../hooks/common/useSet";
import { API_GENERATE_OTP } from "../../../constants/apis";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
  const navigate = useNavigate();
  const { form, onChange } = useForm();
  const { uploadData, submitting } = useSet();

  const handleSubmit = () => {
    const phone_number = `256${form.phone_number}`;
    uploadData({
      api: API_GENERATE_OTP,
      params: { phone_number },
      onSuccess: (data: any) => {
        navigate("/verify-otp", {
          state: {
            phone_number,
            otp: data?.otp,
          },
        });
        // LOG DATA
        console.log("DATA", data);
      },
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  const options = [
    {
      value: "256",
      label: "+256",
    },
    {
      value: "254",
      label: "+254",
    },
  ];
  return (
    <form onSubmit={onSubmit}>
      <div className="flexColumn gap20">
        <div className="semiBold font18">
          Continue with <span className="mainColor">Phone Number</span>
        </div>
        <div className="x100 flexColumn gap10">
          <div className="font13">
            Phone Number <span className="redColor">*</span>
          </div>
          <Space.Compact className="x100">
            <Select
              defaultValue={options[0]}
              options={options}
              style={{ height: 40 }}
            />
            <Input
              placeholder="Enter phone number"
              style={{ height: 40 }}
              maxLength={9}
              name="phone_number"
              onChange={onChange}
              required
              className="font14"
            />
          </Space.Compact>
          <div className="greyColor font12">
            We will send you an <span className="semiBold">6-digit</span> OTP
            code on this number to verify your account.
          </div>
        </div>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Continue
        </Button>
      </div>
    </form>
  );
};
