import { APP_NAME, LOGIN } from "../../../constants/constants";
import styled from "styled-components";
import { LogoSVG } from "../../../assets/svg/Svgs";
import { LoginForm } from "./LoginForm";
import { VerifyOTP } from "./VerifyOTP";
import { useLocation } from "react-router-dom";
export const LoginPage = () => {
  const { pathname } = useLocation();
  const isLogin = pathname.includes(LOGIN);
  return (
    <Wrapper className="flexCenter vh100 pattern1">
      <CenterWrapper className="flexColumn gap20 flexCenter">
        <div
          className="flexCenter flexColumn x100 textCenter"
          style={{ marginBottom: 30 }}
        >
          <div className="p20">
            <LogoSVG width={100} height={100} />
          </div>
          <div className="bold mb10" style={{ fontSize: 30 }}>
            Welcome to <span className="extraBold mainColor">{APP_NAME}</span>
          </div>
          <div className="greyColor font13">
            Connect with your <b>{APP_NAME}</b> today! 👋
          </div>
        </div>

        <FormWrapper className="p30 whiteBg radius20 x100">
          {isLogin ? <LoginForm /> : <VerifyOTP />}
        </FormWrapper>
      </CenterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const FormWrapper = styled.div`
  box-shadow: rgba(145, 158, 171, 0.5) -40px 40px 80px;
`;
const CenterWrapper = styled.div`
  width: 25%;
`;
