// APP DATA
export const CONST_CREDENTIALS_KEY = "YOYAAPP_DGD^#&#^#*#&#*(^GDGE#^*&*%%%";
export const PASSWORD = "password";
export const FORMDATA = "formdata";
export const APP_NAME = "YoyaApp";
export const APP_EMAIL = "info@yoyatechnologies.com";

// CONSTANTS
export const CHATS = "chats";
export const MESSAGES = "messages";
export const GROUP = "group";
export const THIRD_PARTY = "third_party";
export const CURRENT_COLOR = "currentColor";
export const LOGIN = "login";
export const DEFAULT_IMAGE_WIDTH = 250;
export const UNKNOWN = "Unknown";
export const SYSTEM = "system";
export const INDIVIDUAL = "individual";
export const ONLINE = "online";
export const ONLINE_STATUS = "online_status";
