import { Avatar } from "antd";
import { primaryColor } from "../../constants/theme";
import { userText } from "../../utils/helperUtils";
import { UsersSVG } from "../../assets/svg/icons/UsersSVG";
import { GROUP, THIRD_PARTY } from "../../constants/constants";
import {
  BUBBLE_PRIMARY_COLOR,
  THIRD_PARTY_COLOR,
} from "../../constants/colors";

export const AvatarView = ({
  size,
  color: mColor,
  icon: mIcon,
  text,
  type,
  onClick,
}: {
  text: any;
  size?: number;
  color?: any;
  icon?: any;
  type?: any;
  onClick?: any;
}) => {
  const getAvatar = () => {
    let icon = mIcon;
    let color = mColor;
    let iconColor = "white";
    switch (type) {
      case GROUP:
        icon = UsersSVG;
        color = BUBBLE_PRIMARY_COLOR;
        iconColor = primaryColor;
        break;
      case THIRD_PARTY:
        color = THIRD_PARTY_COLOR;
        break;

      default:
        color = primaryColor;
        break;
    }
    return { icon, color, iconColor };
  };

  const { icon: Icon, color, iconColor } = getAvatar();

  return (
    <div style={{ width: size, height: size }} className="pointer">
      <Avatar
        onClick={onClick}
        size={size}
        style={{ backgroundColor: color }}
        icon={Icon && <Icon fill={iconColor} />}
      >
        {userText(text)}
      </Avatar>
    </div>
  );
};
