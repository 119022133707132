import { CURRENT_COLOR } from "../../../constants/constants";

export const ForwardedSVG = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      height={24}
      fill={CURRENT_COLOR}
      {...props}
    >
      <path d="M17.244 4a2 2 0 00-3.414 1.414V7H9a9.01 9.01 0 00-9 9v7a1 1 0 002 0 6.006 6.006 0 016-6h5.83v1.586A2 2 0 0017.244 20l5.879-5.879a3 3 0 000-4.242z" />
    </svg>
  );
};
