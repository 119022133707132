import styled from "styled-components";
import { Appbar } from "../common/Appbar";

export const DashboardLayout = ({ children }: any) => {
  return (
    <Wrapper className="vh100 container">
      <Appbar />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
`;

const ContentWrapper = styled.div`
  height: calc(100% - 61px);
`;
