import { jwtDecode } from "jwt-decode";
import { CONST_CREDENTIALS_KEY } from "../constants/constants";
import moment from "moment";
import { BASE_URL } from "../constants/config";

export const setCredentials = (value: any) => {
  try {
    window.localStorage.setItem(CONST_CREDENTIALS_KEY, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

export const removeCredentials = () => {
  try {
    localStorage.clear();
  } catch (e) {
    // saving error
  }
};

export const decodeUser = (token: any) => {
  try {
    const decoded = jwtDecode<any>(token);
    const user_id = decoded.user_id;
    return { id: user_id };
    // return decoded?.user;
  } catch (err) {
    // saving error
  }
};

export const getCredentials = () => {
  try {
    const res = window.localStorage.getItem(CONST_CREDENTIALS_KEY);
    return res ? JSON.parse(res) : null;
  } catch (e) {
    // saving error
  }
};

export function stringify(text: string) {
  return text?.replaceAll?.("_", " ");
}

export const userText = (value: any) =>
  value?.replace("~ ", "")?.substring(0, 1).toUpperCase();

export function formatPhoneNumber(x: any) {
  const formatted = x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return `+${formatted || ""}`;
}

export const getTimestamp = (value: any) => {
  const date = moment(value);
  const now = moment();
  const isSameDay = date.isSame(now, "day");
  const isYesterday = date.isSame(now.clone().subtract(1, "day"), "day");

  let format = "";
  if (isSameDay) {
    format = "hh:mm A";
  } else if (isYesterday) {
    return "Yesterday";
  } else {
    format = "DD/MM/YY";
  }

  let timestamp = date.format(format);
  timestamp = timestamp.startsWith("0") ? timestamp.substring(1) : timestamp;

  return timestamp;
};

export const getAttachmentImages = (attachments: any[]) => {
  if (attachments?.length > 0) {
    return attachments?.map((attachment) => {
      const url = `https://${BASE_URL}${attachment?.file}`;
      return url;
    });
  }
  return [];
};

export const cleanNewMessages = (newData: any, prev: any) => {
  // get the last message
  const lastMessage = prev[prev?.length - 1];
  const filtered = newData?.filter((obj: any) => obj?.id !== lastMessage?.id);
  return filtered;
};

export const updateUserMessage = (newData: any, prev: any) => {
  // get the new message
  const newMessage = newData?.content;

  // find initial sent message
  const index = prev?.findIndex(
    (message: any) => message?.content === newMessage && !message.id
  );

  // Message found in previous messages
  if (index !== -1) {
    // replace existing message
    prev[index] = newData;
  }
  return prev;
};

export const returnObject = (show: boolean, object: any) => {
  return show ? object : [];
};

export const getLastSeen = (value: any) => {
  const date = moment(value);
  const now = moment();
  const isSameDay = date.isSame(now, "day");
  const isYesterday = date.isSame(now.clone().subtract(1, "day"), "day");
  let format = "HH:mm";
  let prefix = "on";

  if (isSameDay) {
    prefix = "today at";
  } else if (isYesterday) {
    prefix = "yesterday at";
  } else {
    format = "DD/MM/YY, HH:mm";
  }
  let timestamp = date.format(format);

  return `last seen ${prefix} ${timestamp}`;
};
