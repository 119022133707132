import { ChatsPage } from "../components/pages/chats/ChatsPage";
// import { Landing } from "../components/pages/landing/Landing";
import { PrivacyPolicy } from "../components/pages/landing/PrivacyPolicy";
import { LoginPage } from "../components/pages/login/LoginPage";
import { CHATS, LOGIN } from "./constants";

// external use
export const guestRoutes = [
  // {
  //   path: "/",
  //   component: <Landing />,
  // },
  {
    path: LOGIN,
    component: <LoginPage />,
  },
  {
    path: "verify-otp",
    component: <LoginPage />,
  },
  {
    path: "/privacy",
    component: <PrivacyPolicy />,
  },
];

export const standardRoutes = [
  // {
  //   path: "/",
  //   component: <></>,
  // },
  {
    path: CHATS,
    component: <ChatsPage />,
  },
];
